*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
nav{
  height: 80px;
  background-color: black;
  color: white;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center !important;
padding-left: 50px;
padding-right: 50px;
position: fixed;
width: 100%;
z-index: 1000;
}
nav h3{
  font-size: 24px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  color: white;
  margin-bottom: 0px;
}
nav ul{
  display: flex;
  flex-direction: row;
  align-items: center;gap: 50px;
}
nav ul li{
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
nav button{
padding: 15px 25px 15px 25px;
background: none;
border: 1px solid white;
border-radius: 5px;
font-size: 14px;
font-weight: 700;
font-family: "DM Sans", sans-serif;
color: white;
transition: background-color 0.3s ease, color 0.3s ease;

}
nav button:hover{
  background-color: rgba(35, 166, 240, 1);
  border: 1px solid rgba(35, 166, 240, 1);
}
.banner{
  background-image: url(./Assets/background.png);
  padding: 200px 100px;
  background-size: cover;
}
.banner h2{
  font-size: 60px;
font-weight: 400;
font-family: "DM Sans", sans-serif;
color: white;
}
.banner h2 span{
  font-size: 100px;

}
.banner button{
 padding: 15px 25px 15px 25px;
background: none;
border-radius: 5px;
border: 1px solid white;
color: white;
font-family: "DM Sans", sans-serif;
font-size: 14px;
font-weight: 700;
}
.banner p{
  color: white;
font-family: "DM Sans", sans-serif;
font-size: 20px;
font-weight: 400;
}
.ourpartner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  
}
.ourpartner p{
  font-size: 20px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.ourpartner h3{
  font-size: 40px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.ourpartner h3 span{
  color: rgba(35, 166, 240, 1);

}

.ourpartner img{
  width: 90%;
}
.about-divss{

  background-image: url(./Assets/—Pngtree—minimalist\ galaxy\ intersterllar\ night\ infinity_1597901.jpg);
  background-position: center;
  background-size: cover;

}
.about-div{
  background-color: rgba(0, 0, 0, 0.524);
  padding: 150px 100px;

}
.about-div-heading{
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  text-align: center;
}
.about-div-heading h5{
  font-size: 20px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.about-div-heading h3 span{
  
  color: rgba(35, 166, 240, 1);
}
.about-div-heading h3{
  font-size: 40px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 15px;
}
.about-div-heading p{
  font-family: "DM Sans", sans-serif;
font-size: 16px;
font-weight: 400;
margin-top: 10px;
}
.entrepreneur{
  color: white;
}
.entrepreneur h3{
  font-size: 24px;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
}
.entrepreneur p{
  font-size: 14px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgba(255, 255, 255, 1);
  margin-top: 15px;

}
.entrepreneur-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.entrepreneur img {
  transition: transform 0.5s ease-in-out;
  transform-style: preserve-3d;
}

.entrepreneur:hover img {
  transform: rotateY(180deg);
}
.Find{
  display: flex;
  flex-direction: row;
  background-color: rgba(246, 252, 255, 1);
  padding: 50px;
}
.Find-left{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 50%;
}
.Find-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.Find-right img{
  width: 80%;
}
.Find-left p{
  font-size: 20px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.Find-left h4 span{
  color: rgba(35, 166, 240, 1) !important;

}
.Find-left h4{
  font-size: 40px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.Find-left h3{
  font-size: 44px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.COURSES{

}
.COURSES p{
  font-size: 20px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin-top: 8px;


}
.COURSES h2 span{
  color: rgba(35, 166, 240, 1);

}
.COURSES h2{
  font-size: 40px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  
}
.COURSES-card{
  width: 30% !important;
}
.COURSES-card img{
  width: 100%;
  height: 300px;
  border-radius: 10px;
}
.COURSES-card h3{
  font-size: 16px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  margin-top: 8px;
}
.enrollnow{
  padding: 10px 20px 10px 20px;
  border: 1px solid rgba(35, 166, 240, 1);
  color:  rgba(35, 166, 240, 1);
  
  font-size: 14px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  background: none;
width: 90%;
transition: background-color 0.3s ease, color 0.3s ease;
}
.COURSES-card:hover .enrollnow {
  background-color: rgba(35, 166, 240, 1);
  color: white;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.COURSES h4{
  margin-top: 8px;
  
}
.COURSES-card{
  box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
width: auto;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
padding-bottom:20px;

}
.COURSES-cards{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 100px;
  padding-right: 100px;
  flex-wrap: wrap;
  gap: 50px;
}
.COURSES-card-text{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  
}
.COURSES-card-text h3{
  font-size: 14px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;

}
.COURSES-card-text h4{
  font-size: 18px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;

}
.COURSES-heading{
  text-align: center;
}
.blogs{
  background-color: rgba(236, 248, 255, 1);
  padding-top: 50px;
  padding-bottom: 50px;
}
.blogs p{
text-align: center;
}
.blogs h3{
  text-align: center;

  font-size: 40px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  }

  .blogs-card{
width: 25%;
border: 1px solid rgb(221, 221, 221);
transition: background-color 0.5s ease, color 0.3s ease;
border-radius: 10px;
  }

  .blogs-card:hover{
    background-color: white;
box-shadow: 10px 10px 25px -21px rgba(0,0,0,0.75);
  }

  .blogs-card-text{
    padding: 20px !important;
  }
  .blogs-card img{
    width: 100%;
    border-radius: 10px;

  }
  .blogs-card h3{
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-align: start;
  }
  .blogs h3 span{
    color: rgba(0, 150, 199, 1);

  }
  .dates{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .dates p{
    color: rgba(128, 128, 128, 1);
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;

  }
  .dates h4{
    color: rgba(0, 150, 199, 1);
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  .blogs-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
  .PARTNERS p{
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .PARTNERS h3 span{
    color: rgba(0, 150, 199, 1);

  }
  .PARTNERS h3{
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
  }
  .PARTNERS img{
    height: 50px;
  }
  .marquee{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
  }

  .joinus{
    display: flex;
    flex-direction: row;

    padding-left: 50px;
    padding-right: 50px;


  }
  .joinus-left{
    width:  40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .joinus-left img{
    width: 80%; 

  }

  .joinus-right{
    width:  70%;
    
  }
  .joinus-right{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .joinus-right h2{
    font-family: "DM Sans", sans-serif;
    font-size: 40px;
    font-weight: 700;
  }
  .joinus-right h2 span{
    color: rgba(0, 150, 199, 1);
    
  }
  .joinus-right h3{
    font-family: "DM Sans", sans-serif;
    font-size: 15px;
    font-weight: 400;
  }
  .about-page{
    padding: 50px;
  }
  .about-page p{
    color: rgba(0, 150, 199, 1);
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .about-page-div{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }
  .about-page-div h3{
    width: 50%;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .about-page-div h2{
    width: 50%;
    font-size: 40px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;

  }
  .builtcareer{
    background-color: rgba(236, 248, 255, 1);
    display: flex;
    flex-direction: row;
    padding: 50px;
  }
  .builtcareer-left{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .builtcareer-right{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
  }
  .builtcareer-left img{
    width: 60%;

  }
  .builtcareer-right p{
    color: rgba(0, 150, 199, 1);
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .builtcareer-right h2{
    font-family: "DM Sans", sans-serif;
    font-size: 40px;
    font-weight: 500;
  }
  .builtcareer-right h3{
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(77, 77, 77, 1);
  }
  .builtcareer-right button{
    padding: 15px 25px 15px 25px;
    background-color: rgba(35, 166, 240, 1);
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    border-radius: 5px;border: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .builtcareer-right button:hover{
    background-color: rgba(236, 248, 255, 1);
    border: 1px solid rgba(35, 166, 240, 1);
    color: rgba(35, 166, 240, 1);
  }
  .OURVALUES{
    padding: 50px;
  }
  .OURVALUES p{
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .OURVALUES h2 span{
    color: rgba(0, 150, 199, 1);

  }
  .OURVALUES h2{
    font-family: "DM Sans", sans-serif;
    font-size: 40px;
    font-weight: 500;
    text-align: center;

  }
  .OURVALUES h3{
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(77, 77, 77, 1);
    text-align: center;

  }
  .OURVALUES-card{
    Width:
295px;
Height: auto;
border-radius: 8px;
padding: 30px;
border: 1px solid rgba(235, 235, 235, 1);
transition: box-shadow 0.3s ease, color 0.3s ease;

  }
  .OURVALUES-card:hover{
    box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);

  }
  .OURVALUES-card h3{
    font-family: "DM Sans", sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-align: start;
    color: black;
  }
  .OURVALUES-card p{
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: start;
    color: black;
  }
  .OURVALUES-cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: auto;
    gap: 20px;
  }
  .letstart{
    padding: 100px;
  }
  .letstart-div{
    padding: 50px;
    background-color: rgba(236, 248, 255, 1);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
  }
  .let-img2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

  }
  .let-img2 p{
    color: rgba(35, 166, 240, 1);
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .let-img2 h3 span{
    color: rgba(35, 166, 240, 1);

  }
  .let-img2 h3{
    
    font-family: "DM Sans", sans-serif;
    font-size: 50px;
    font-weight: 500;
  }
  .let-img2 h4{
    
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: rgba(76, 76, 76, 1);
  }
  .btns{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .btns button{
    padding: 15px 25px 15px 25px;
    background-color: rgba(35, 166, 240, 1);
    color: white;
    border-radius: 6px;
    border: none;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
transition: background-color 0.3s ease, color 0.3s ease;
border: 1px solid rgba(35, 166, 240, 1);

  }
  .btns button:hover{
    border: 1px solid rgba(35, 166, 240, 1);
    background: none;
    color: rgba(35, 166, 240, 1);
  }
  .contactus-div{
    padding: 100px;
  }
  .contactus{
    padding: 10px;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
  }
  .contactus-left{
    width: 40%;
    background-color: black;
    padding: 30px;
    border-radius: 10px;

  }
  .contactus-right{
    width: 60%;
  }
  .contactus-left h3{
    font-family: "DM Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
    color: white;
  }
  .contactus-left h4{
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: rgba(201, 201, 201, 1);
  }
  .contactus-left p{
    color: white;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .social-links{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .contactips{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .contactips-div{
    display: flex;
    flex-direction: row;
    gap: 30px;
    
  }
  .contactus-right{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .new-contact-right label{
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-weight: 500;
    /* margin-bottom: 15px; */
  }
  .new-contact-right input{
    border: none;
    border-bottom: 1px solid rgba(141, 141, 141, 1);
    height: 40px;
  }
  .new-contact-right button{
    float: inline-end;
    padding: 15px 48px 15px 48px;
    border-radius: 5px;
    background-color: rgba(35, 166, 240, 1);
    color: white;
    border: none;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  .OurCompanies{
    display: flex;
    flex-direction: row;
  }
  .OurCompanies-para{
    background-color: rgba(236, 248, 255, 1);
    padding: 30px;
    border-radius: 10px;
  }
  .OurCompanies-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px;
  }
  .OurCompanies-right{
    width: 60%;
    padding: 20px 50px;

  }
  .OurCompanies-right p{
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .OurCompanies-left h2{
    font-family: "DM Sans", sans-serif;
    font-size:40px;
    font-weight: 500;
  }
  .OurCompanies-left p{
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: rgba(35, 166, 240, 1);
  }
  .legendary{
    background-color: black;
    color: white;
    padding: 50px;

  }
  .legendary-left{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .legendary h2{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    text-align: center;
  }
  .legendary-left h3{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .legendary-left p{
    color: rgba(35, 166, 240, 1);
    font-size: 24px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .legendary-left h4{
    color: rgba(204, 204, 204, 1);
    font-size: 16px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  
  }
  .lifecycle{
    text-align: center;
    padding: 50px;
  }
  .lifecycle p{
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
  }
  .lifecycle h3{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .lifecycle h3 span{
    color: rgba(35, 166, 240, 1);

  }
  .lifecycle h4{
    font-size: 16px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(76, 76, 76, 1);
    margin-top: 10px;
  }
  .HIGHLISGHTS{
    display: flex;
    flex-direction: row;
    background-color: rgba(236, 248, 255, 1);
    padding: 20px 50px;
  }
  .HIGHLISGHTS-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .HIGHLISGHTS-right{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .HIGHLISGHTS-right img{
    width: 80%;
  }
  .HIGHLISGHTS-left p{
    font-size: 20px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .HIGHLISGHTS-left h3 span{
    color: rgba(35, 166, 240, 1);

  }
  .HIGHLISGHTS-left h3{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .HIGHLISGHTS-left h4{
    font-size: 31px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(76, 76, 76, 1);
  }
  .Pioneering{
    text-align: center;
    padding: 50px 100px;
  }
  .Pioneering h3{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .Pioneering h3 span{
    color: rgba(35, 166, 240, 1);
  }
  .Pioneering p{
    font-size: 16px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(76, 76, 76, 1);
  }
  .ngo{
    display: flex;
    flex-direction: row;
  }
  .ngo-left{
    width: 50%;
    padding: 50px;
  }
  .ngo-right{
    width: 50%;

  }
  .ngo-right img{
    width: 100%;
  }
  .ngo-left p{
    font-size: 20px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(35, 166, 240, 1);
  }
  .ngo-left h3{
    font-size: 40px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(37, 43, 66, 1);
  }
  .exps{
padding: 50px;
display: flex;
flex-direction: row;
  }
  .exps-left{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .exps-left img{
    width: 70%;
  }
  .exps-right{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .exp-text{
    display: flex;
    flex-direction: row;
    padding: 30px;
    justify-content: space-between;
    gap: 30px;
  }
  .exp-text1{
    width: 45%;
    padding-top: 50px;
  }
  .exp-text1 p{
    font-size: 18px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .exp-count{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .exp-count1{
    width: 200px;
  }

  .exp-count1 p{
    color: rgba(76, 76, 76, 1);
    font-size: 18px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  }
  .exp-count1 h4 span{
    color: rgba(35, 166, 240, 1);

  }
  .exp-count1 h4{
    font-size: 30px;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;
  }
  .donate{
    padding: 100px;
  }
  .donate-div{
    background-color: rgba(236, 248, 255, 1);
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .footer{
    background-color: rgba(37, 43, 66, 1);
    color: white;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    gap: 50px;
    padding: 100px 100px;
    flex-wrap: wrap;
  }
  .footer ul{
    font-size: 24px;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;
  }
  .footer ul span{
    color: rgba(35, 166, 240, 1);

  }
  .footer ul li{
    list-style: none;
    color: rgba(255, 255, 255, 1) !important;
    font-size: 14px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    margin-top: 20px;
  }
  .footer  h3{
    font-size: 24px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
  }
  .footer-bottom{
    background-color: rgba(37, 43, 66, 1);
height: 76px;
display: flex;
justify-content: center;
align-items: center;
border-top: 1px solid rgba(102, 102, 102, 1);
padding-left: 15px;
padding-right: 15px;
  }
  .footer-bottom p{
    font-size: 14px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(170, 170, 170, 1);
    text-align: center;
    margin-bottom: 0px;
  }
  .donate-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
  }
  .donate-section p{
    font-size: 18px;
    font-weight: 300;
    font-family: "DM Sans", sans-serif;
    color: rgba(76, 76, 76, 1);
  }
  .donate-div h3{
    font-size: 51px;
    font-weight: 500;
    font-family: "DM Sans", sans-serif;
  }
  .donate-div{

  }
  .donate button{
    Width:
184.2px;
Height:
57px;
background-color: rgba(35, 166, 240, 1);
border-radius: 50px;
color: white;
font-size: 18px;
font-weight: 700;
font-family: "DM Sans", sans-serif;
border: none;
transition: background-color 0.3s ease, color 0.3s ease;

  }

  




  .donate button:hover{
background: none;
border: 1px solid rgba(35, 166, 240, 1);
color: rgba(35, 166, 240, 1);
  }
  .globe-left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  .globe-right{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .globe{
    display: flex;
    flex-direction: row;
  }
  .mission{
    color: white;
    background-color: black;
    padding: 50px;
  }
  .mission h2{
    font-size: 40px;
    font-weight: 700;
    font-family: "DM Sans", sans-serif;
    text-align: center;
  }
  .mission h4{
    color: rgba(204, 204, 204, 1);
    font-size: 16px;
font-weight: 400;
font-family: "DM Sans", sans-serif;
text-align: center;

  }
  .globe-right img{
    width: 80%;
  }

  .bi-list{
    display: none;
    font-size: 20px;
  }
  @media(max-width:970px){
    nav ul , nav button{
    display: none;
    }
    .nav{
      padding-left: 30px !important;
    padding-right: 30px !important;
    }
  
  .bi-list{
    display: block;
  }
  
  }
  
  .bi-list {
    cursor: pointer;
  }
  
  /* Styles for the toggleable div */
  .toggleDiv {
    /* width: 100%; */
    height: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color:rgba(19, 11, 70, 0.25);
    border: 1px solid #787878;
    border-radius: 50px;
    color: rgb(255, 255, 255);
    text-align: center;
  margin-top: 150px;
  padding-top: 50px;
  
  }
  .toggleDiv ul{
    padding-left: 0px;
  }
  .toggleDiv ul li{
    font-family: "Manrope", sans-serif;
    /* color: rgb(255, 255, 255); */
  
    font-weight: 700;
    font-size: 16px;
    list-style: none;
    margin-bottom: 25px;
  }
  .toggleDiv-ops{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
  z-index: 100;
    top: 80px;
  }
  /* Navbar.css */

/* Keyframes for the slide-in animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Style for the toggle div */
.toggleDiv {

  animation: slideUp 0.5s ease-out; /* Apply the slide-up animation */
  z-index: 1000; /* Ensure it's on top */
}

.toggleDiv {
  padding: 20px; /* Padding for content */
}

  @media(max-width:768px){
    .banner{
      background-image: url(./Assets/background.png);
      padding: 100px 30px;
      background-position: center;
    }
  }
  @media(max-width:768px){
.banner h2{
  font-size: 40px;

}
.banner h2 span{
  font-size: 45px;

}
.about-div{
  padding: 50px 30px;

}
.about-div-heading{
  padding-left: 0px;
  padding-right: 0px;
}
.about-div-heading h5{
  font-size: 15px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgba(35, 166, 240, 1);
}
.about-div-heading h3{
  font-size: 25px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 15px;
}
.about-div-heading p{
  font-size: 12px;
}
.entrepreneur-div{
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.blogs h3{
  text-align: center;

  font-size: 25px;
}
  }
  @media(max-width:768px){
.entrepreneur{
  width: 45%;
}
  }
  @media(max-width:850px){
    .Find-left h4{
      font-size: 25px;
      font-weight: 500;
      font-family: "DM Sans", sans-serif;
    }
    .Find-left h3{
      font-size: 25px;

    }
.COURSES-cards{
  padding-left: 30px;
  padding-right: 30px;
}
.blogs-card h3{
  font-size: 15px;
}
.about-page-div h2{
  font-size: 25px;

}
  }
  @media(max-width: 600px){
    .Find{
      flex-direction: column-reverse;
      gap: 50px;
    }
.Find-left , 
.Find-right  {
  width: 100%;
}

  }
  @media(max-width: 1210px){
    .COURSES-card{
      width: 25% !important;
    }
  }
  @media(max-width: 950px){
    .COURSES-card{
      width: 45% !important;
    }
  }
  @media(max-width: 600px){
    .COURSES-card{
      width: 100% !important;
    }
  }

  @media(max-width: 700px){

  .blogs-cards{
flex-direction: column;
gap: 30px;
  }
  .blogs-card{
    width: 90%;
  }
  .blogs-card h3{
    font-size: 20px;
    text-align: start;
  }
  .PARTNERS h3{
    font-size: 25px;
  }
}
@media(max-width: 700px){
  .joinus{
flex-direction: column;
  }
  .joinus-left{
    width:  100%;
  }
  .joinus-right{
    width:  100%;
    
  }
  .joinus-left{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .joinus{
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer{
    padding: 30px 30px;

  }
}
@media(max-width: 700px){
  .about-page-div{
    flex-direction: column;
  }
  .about-page-div h3{
    width: 100%;
  }
  .about-page-div h2{
    width: 100%;
  }
  .about-page{
    padding: 30px;
  }
}
@media(max-width: 1200px){
  .builtcareer-left img{
    width: 80%;
  }
}
@media(max-width: 1000px){
  .builtcareer{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;

  }
  .builtcareer-left{
    width: 100%;
  }
  .builtcareer-right{
    width: 100%;
  }
  .contactus-div{
    padding: 30px;
  }
}
@media(max-width: 1050px){
  .letstart{
    padding: 20px;

  }
  .let-img2 h3{
font-size: 30px;

}
}
@media(max-width: 930px){
.let-img{
  display: none;
}

}
@media(max-width: 700px){
.btns{
  display: flex;
  flex-direction: column;
}
.letstart-div{
  padding: 20px;
}
.OURVALUES{
  padding: 30px;
}
}
@media(max-width: 768px){
  .contactus{
    display: flex;
    flex-direction: column-reverse;
    gap: 50px;
  }
 
 
  .contactus-left{
    width: 100%;
  }
  .contactus-right{
    width: 100%;
  }
}
@media(max-width: 768px){
  .contactips-div{
    flex-direction: column;
  }
  .OurCompanies{
    flex-direction: column;
  }
  .OurCompanies-left{
    width: 100%;
    padding: 30px;

  }
  .OurCompanies-right{
    width: 100%;
    padding: 0px;

}
.OurCompanies-left h2{
  font-family: "DM Sans", sans-serif;
  font-size:30px;
  font-weight: 500;
}

.legendary-left h3{
  font-size: 25px;
}
.legendary h2{
  font-size: 25px;
}
.lifecycle{
  padding: 30px;
}
.lifecycle h3{
  font-size: 25px;
}
.lifecycle p{
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
}
.lifecycle img{
  width: 100%;

}
}
.lifecycle img{
  width: 100%;

}
.legendary-div{
  display: flex;
  flex-direction: row;
}
@media(max-width: 970px){
  .legendary-div{
    flex-direction: column;
  }
  .legendary-left{
    width: 100%;
  }
}
@media(max-width: 900px){
  .HIGHLISGHTS-left h3{
    font-size: 25px;
  }
  .HIGHLISGHTS-left h4{
    font-size: 20px;
  }
}
@media(max-width: 768px){
  .HIGHLISGHTS{
    flex-direction: column;
    padding: 20px 30px;

  }
  .HIGHLISGHTS-left{
    width: 100%;
  }
  .HIGHLISGHTS-right{
    width: 100%;
  }
  .Pioneering{
    text-align: center;
    padding: 50px 30px;
  }
}
@media(max-width: 768px){
  .ngo-left{
    padding: 30px;
  }
  .ngo-left h3{
    font-size: 25px;
  }
}
@media(max-width: 1200px){
  .ngo{
    display: flex;
    flex-direction: column;
  }
  .ngo-left , .ngo-right{
    width: 100%;
  }
}
@media(max-width: 900px){
.exps{
  display: flex;
  flex-direction: column;
}

.exps-right{
  width: 100%;
}
.exps-left{
  width: 100%;

}
}
@media(max-width: 650px){
  .exp-text{
    flex-direction: column;
    gap: 10px;

  }
  .exp-text1{
    width: 100%;
  }
  .exps{
    padding: 30px;
  }
}
@media(max-width: 500px){
  .exp-count{
    display: none;
  }
}
@media(max-width: 900px){
  .donate{
    padding: 50px;
  }
}
@media(max-width: 768px){
  .donate{
    padding: 20px;
  }
 
  .donate-div{
    flex-direction: column;
    gap: 20px;
  }
}
@media(max-width: 600px){
  .donate-div{
    padding: 30px;

  }
  .donate-div h3{
    font-size: 30px;
  }
  .donate-section{
    display: flex;
    flex-direction: column;
  }
  .mission{
    padding: 30px;

  }
  .mission h2{
    font-size: 25px;
  }
  .mission h4{
    font-size: 13px;
  }
  .globe{
    flex-direction: column;
  }
  .globe-left{
    width: 100%;
  }
  .globe-right{
    width: 100%;
  }
}
.none{
  color: white;
  text-decoration: none;
}
.socialicons{
  font-size: 20px;
  display: flex;
  gap: 20px;
}
.socialicons i{
  font-size: 20px;
}
.blog-detail{
  display: flex;
  flex-direction: column;

}
.blog-detailleft,.blog-detailright{
  width: 100%;
}
.blog-detailleft{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}
.blog-detailright img{
  width: 100%;
 



}
.blog-detailleft{
  padding: 50px;
}
.blog-detailleft h2{
  font-size: 40px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgba(0, 150, 199, 1);
}
.blog-detailleft h3{
  font-size: 15px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;


}
.blog-detailleft p{
  font-size: 25px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;

}
@media(max-width: 900px){
  .blog-detail{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  
  }
  .blog-detailleft,.blog-detailright{
    width: 100%;
  }
  .blog-detailright img{
    width: 50%;
    height: auto;
  
  }
  .blog-detailright{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media(max-width: 600px){
  .blog-detailright img{
    width: 100%;
    height: auto;
  
  }
  .blog-detailleft{
    padding: 30px;
  }
  .blog-detailleft h2{
    font-size: 30px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
    color: rgba(0, 150, 199, 1);
  }
  .blog-detailleft h3{
    font-size: 15px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  
  
  }
  .blog-detailleft p{
    font-size: 20px;
    font-weight: 400;
    font-family: "DM Sans", sans-serif;
  
  }
}
.modal-div{
  display: flex;
  flex-direction: row;
}
.modal-div-left{
  width: 50%;
  
}
.modal-div-left img{
  width: 100%;
}
.modal-div-right{
  width: 50%;
  padding: 30px;

}
.modal-div-right h2{
  font-size: 25px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgba(0, 150, 199, 1);
}
.modal-div-right h3{
  font-size: 20px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin-top: 15px;
  
}
.modal-div-right p , .modal-div-right h4{
  font-size: 15px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin-top: 15px;
  
}
@media(max-width: 768px){
  .modal-div{
    display: flex;
    flex-direction: column;
  }
  .modal-div-right , .modal-div-left{
    width: 100%;
}
}
ul{
  margin-bottom: 0rem !important;
}
.speaker{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.Workshop-card{
  width: 350px;
  position: relative;
 border-radius: 15px;
  box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);
  

}
.Workshop-card img{
  width: 100%;

}
.Workshop-text{
  /* padding: 15px; */
  position: relative;
}
.Workshop-text h2{
  font-size: 25px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 50px;
  background-color: rgba(10, 10, 10, 0.543);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 15px;
  position: absolute;
  margin-top: -50px;
}
.eventcity{
  position: absolute;
  /* top: 0; */
  z-index: 100;
  top: 0;
  right: 0;
  background-color: rgba(0, 150, 199, 1);
  border-radius: 15px 0 10px 0;
  color: white;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventdate{
  position: absolute;
  /* top: 0; */
  z-index: 100;
  top: 0;
  background-color: rgba(0, 150, 199, 1);
  border-radius: 15px 0 10px 0;
  color: white;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  font-size: 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Workshop-text h3{
  font-size: 18px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin-top: 15px;

  
}
.Workshop-text h4{
  font-size: 15px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  /* margin-top: 15px; */

  
}
.Workshop-text h5 , .Workshop-text p{
  font-size: 15px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  /* margin-top: 15px; */
  
}
.Workshop-text p{
  color: rgba(0, 150, 199, 1);
}
.Workshop-cards{
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Workshop-heading{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Workshop-heading h2{
  font-size:40px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  
}
.Workshop-heading h2 span{
  color: rgba(0, 150, 199, 1);
}
.newsletter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.newsletter input{
height: 58px;
border-radius: 5px 0 0 5px;
  width: 50%;
border: 1px solid rgba(230, 230, 230, 1);
background-color: rgba(249, 249, 249, 1);
padding-left: 20px;
}
.newsletter button{
  Width:
117px;
Height:
58px;
background-color: rgba(35, 166, 240, 1);
border: 1px solid rgba(230, 230, 230, 1);
color: white;
border-radius: 0px 5px 5px 0px;

}
.donation-box-img{

}
.accordion-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 25px;

}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.accordion-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
}

.accordion-content {
  padding: 30px;

  border-top: 1px solid #00304B;
}
.accordion-content p{
  font-size: 16px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  color: rgba(204, 204, 204, 1);
}
.cause{
padding: 50px;
display: flex;
flex-direction: column;
gap: 50px;

}
.cause-left{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.cause-left h3{
  font-size: 40px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.cause-right{
  width: 100%;
}
.cause-card{
  Width:
400px;

border: 1px solid rgba(235, 235, 235, 1);
border-radius: 15px;
padding: 30px;
}
.cause-card h3{
  font-size: 24px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.cause-card p{
  font-size: 18px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
}
.cause-cards{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 20px;
}
.banner2{
  background-image: url(./Assets/banner2.png) !important;
}
.banner3{
  background-image: url(./Assets/banner3.png) !important;

}
.banner4{
  background-image: url(./Assets/banner4.png) !important;

}
.banner5{
  background-image: url(./Assets/banner5.png) !important;

}
.banner6{
  background-image: url(./Assets/banner6.png) !important;

}
.banner7{
  background-image: url(./Assets/banner7.png) !important;

}
.banner8{
  background-image: url(./Assets/banner8.png) !important;

}
.banner9{
  background-image: url(./Assets/banner9.png) !important;

}
.legendary-cards{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.no{
  background-color: rgba(255, 255, 255, 0.1);
  height: 65px;
  width: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  color: rgba(255, 255, 255, 0.6);
}
.amounts h3{
  font-size: 54px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
}
.leg-name p{
  font-size: 20px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.legendary-right{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media(max-width:768px){
  .legendary-right{
    width: 100%;
  }
  .legendary-div{
    gap: 50px;
  }
  .legendary-cards{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
}
@media(max-width: 1040px){
  .cause{
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .cause-left{
    width: 100%;
    text-align: center;
  }
  .cause-right{
    width: 100%;
  }
  .cause-cards{
    overflow-x: auto;
  }
}
@media(max-width: 768px){
  .cause{
    padding: 30px;
  }


}
.enrollnow2{
width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;

}
.enrollnow2:hover{
  background-color: rgba(35, 166, 240, 1);
  color: white;
}
.footer ul{
  padding-left: 0px;
}
.new-contact{
  display: flex;
  flex-direction: row;
  padding: 50px 100px;
  background-color: #ECF8FF;
}
.new-contact-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.new-contact-icon{
  height: 50px;
  width: 50px;
  border: 1px solid rgba(35, 166, 240, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.new-contact-icons p{
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
}

.new-contact-left{
  width: 50%;
}
.new-contact-right{
  width: 50%;

}
.new-contact-form{
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0px 13px 19px 0px rgba(0, 0, 0, 0.07);

}
@media(max-width: 1070px){
  .new-contact-left{
    width: 40%;
  }
  .new-contact-right{
    width: 60%;
  
  }
  .new-contact{
    display: flex;
    flex-direction: row;
    padding: 50px 30px;
  }
}
@media(max-width: 815px){
  .new-contact-left{
    width: 100%;
  }
  .new-contact-right{
    width: 100%;
  
  }
  .new-contact{
    display: flex;
    flex-direction: column-reverse;
    padding: 50px 30px;
    gap: 50px;
  }
  nav{
    padding-left: 30px;
    padding-right: 30px;
  }
}
h3{
  margin-bottom: 0px;
}

.logo{
  height: 150px;
}
.active{
  color: rgba(35, 166, 240, 1) !important; 
}
.toggleDiv ul li active{
  color: rgba(35, 166, 240, 1) !important; 

}